module.exports = {
  siteTitle: "Javascript Fácil", // <title>
  shortSiteTitle: "Aprende Javascript en tu idioma", // <title> ending for posts and pages
  siteDescription: `¿Listo para aprender y elevar tu nivel como desarrollador web? Javascript Fácil es un sitio enfocado en la enseñanza de Javascript en tu idioma.`,
  siteUrl: "https://javascriptfacil.com",
  pathPrefix: "",
  siteImage: "preview.png",
  siteLanguage: "es",

  /* author */
  authorName: "Christian C. Salvadó",
  authorTwitterAccount: "cmsalvado",

  /* info */
  headerTitle: "Javascript Fácil",
  headerSubTitle: "Aprende Javascript en tu idioma",

  /* manifest.json */
  manifestName: "Javascript Facil",
  manifestShortName: "JS Facil", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/932fb89b9d4049cec5cba357bf0ae388",
  gravatarImgMd5: "",
  authorGravatarImgMd5: "https://www.gravatar.com/avatar/932fb89b9d4049cec5cba357bf0ae388",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/cms" },
    { name: "twitter", url: "https://twitter.com/cmsalvado" },
    { name: "facebook", url: "http://facebook.com/cmsalvado" }
  ]
};
