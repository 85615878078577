/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/css/codemirror.min.css";
import "./src/css/codemirror-prism-okaidia.css";
import "./src/css/drift.css";

export function onInitialClientRender() {
  import("./src/drift");
}
